// Second item in array is the top middle of grid, CurrentSong goes there

export const about_me_list = [
    {
        title: "Education",
        description: `I graduated from the University of Rhode Island with a BS in Computer Science, earning summa cum laude honors (3.77 cumulative GPA, 3.81 GPA 
            within my major). I will be going back to school for a Master's Degree in Finance at Merrimack College, as finance has also been an interest of mine and something that I enjoy 
            learning about.`
    },
    {
        title: "Currently Listening To:",
    },
    {
        title: "Interests",
        description: `When I am not programming or in school, I enjoy going to the gym and playing disc golf. I'm a big sports fan, primarily following Boston teams. 
            Baseball is my favorite sport to watch because of its leisurely pace and the unique atmosphere at the games. I also love to read and am currently reading 
            <em>Atomic Habits</em> by James Clear.`
    },
    {
        title: "Personal Background",
        description: `Being a first-generation college student meant that I couldn't ask my parents for guidance during my college journey. I had to figure things out 
            myself and work extra hard on assignments—principles I apply to all aspects of my life. I enjoy figuring things out on my own and continuously learning new 
            things. You can never know everything, but you can always strive to learn more.`
    },
    {
        title: "Future Goals",
        description: `One of my future goals is to become a lead software engineer or project manager at a company. I often find myself leading group projects in 
            school, even when it’s not by choice. I enjoy guiding a team and ensuring that everyone is on the same page so we can all reach our true potential. 
            This is something I aspire to do on a larger scale in a professional setting. On a more personal note, I aim to learn Italian so that I can talk to my family 
            when I visit.`
    },
    {
        title: "Fun Facts",
        description: `I am a dual citizen of the United States and Italy. I visit Italy every year to see family, which is a big reason why I want to learn Italian. 
            Besides Italy, I would love to travel to Switzerland. I visited Lake Lugano and was taken aback by its beauty; I want to go back and explore more places in 
            Switzerland. My favorite place in the United States is Boston, as I have grown up near there my entire life, and I would love to work there one day.`
    },
]